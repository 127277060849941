import { navigate } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import { useAppDispatch } from "../state/createStore"
import { extendUtm } from "../state/anmeldung/anmeldungSlice"
export interface ForwardProps {
  location: { search: string }
  sourceString: string
}

const ForwardPage = (props: ForwardProps) => {
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (props.location.search !== "") {
      dispatch(extendUtm(props?.location?.search))
    }
    navigate(`/?source=${props.sourceString}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet htmlAttributes={{ lang: "de-de" }}>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="HandheldFriendly" content="true" />
      </Helmet>
    </>
  )
}

export default ForwardPage
